import {makeStyles} from "@mui/styles";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

const {useTranslation} = require("react-i18next");

const useStyles = makeStyles( ({
        formControl: {
            minWidth: 120,
        }
    }
));

const PeriodSelector = ({loading, period,handleSelectorChange}) => {

    const { t } = useTranslation();

    const classes = useStyles();

    return (
        <FormControl className={classes.formControl} disabled={loading}>
            <InputLabel>{t("periodSelector.period")}</InputLabel>
            <Select
                value={period}
                variant={"standard"}
                onChange={ event => handleSelectorChange(event)}
            >
                <MenuItem value={"data1day"}>{t("periodSelector.last_day")}</MenuItem>
                <MenuItem value={"data7day"}>{t("periodSelector.last_7_day")}</MenuItem>
                <MenuItem value={"data15day"}>{t("periodSelector.last_15_day")}</MenuItem>
                <MenuItem value={"data30day"}>{t("periodSelector.last_30_day")}</MenuItem>
                <MenuItem value={"data60day"}>{t("periodSelector.last_60_day")}</MenuItem>
            </Select>
        </FormControl>
    );
};
export default PeriodSelector
